import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './WhitepaperCarouselItem.scss';

import GatsbyImage from 'gatsby-image';
import { customPropTypes } from '../../../util';
import GatsbyLink from '../../GatsbyLink/GatsbyLink';

function WhitepaperCarouselItem({ data: { title, description, icon, cta } }) {
  const className = 'WhitepaperCarouselItem';
  const content = (
    <>
      <h4 className="title">{title}</h4>
      <div className="text-icon-container">
        <p className="text">{description}</p>
        <div className="icon">
          <GatsbyImage
            fluid={icon?.localFile.childImageSharp.fluid}
            alt={icon?.altText}
            placeholderStyle={{ visibility: 'hidden' }}
          />
        </div>
      </div>
      <p className="bottom-cta">{cta?.text}</p>
    </>
  );

  const extraProps = cta.link.startsWith('http') ? { target: '_blank' } : {};

  return (
    <GatsbyLink to={cta?.link} className={className} {...extraProps}>
      {content}
    </GatsbyLink>
  );
}

WhitepaperCarouselItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: customPropTypes.image,
    cta: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
  }).isRequired,
});

WhitepaperCarouselItem.defaultProps = {};

export default memo(WhitepaperCarouselItem);
